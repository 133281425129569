.MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  padding-left: 20px !important;
  font-style: normal !important;
}
.MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 12px 20px !important;
}
.MuiOutlinedInput-input::placeholder {
  color: rgb(52, 52, 52) !important;
}
